import React from 'react'
import {easingFunctions} from '../helpers/utils';


class Text extends React.Component {

    componentDidMount() {

        // Get canvas set dimensions and context
        const canvas = this.refs.text;

        // Canvas Resolution (*2 for HD)
        canvas.height = 950;

        // Size canvas to window dimensions
        if(window.innerWidth < 1024) {
            canvas.width = window.innerWidth * 4;
        } else {
            canvas.width = window.innerWidth * 2;
        }
    
        // Canvas Dimensions
        canvas.style.width = window.innerWidth;
        canvas.style.height = window.innerHeight;
    
        let ctx = canvas.getContext('2d');
    
    
        // Defaults
        let text = {
            string: 'YEAHRIGHT ',
            dx: 10,
            margin: 150,
            color: '#23232f',
            countX: 0,
            countY: 0,
            fontsize: 250
        }
        
        ctx.font = '250px cera-black';
        ctx.textAlign = "left";
    
    
        function DrawText(x, y, fill, ease) {
    
            this.x = x;
            this.y = y;
            this.string = text.string;
            this.fill = fill
            this.easeSpeed = 0.05;
            this.radians = 0;
            this.easeVal = 0;
    
    
            this.draw = function() {
    
                // Ouput text
                if(this.fill) {
                    ctx.shadowOffsetX = 15;
                    ctx.shadowOffsetY = 10;
                    ctx.shadowColor = "#a5a5ae";
                    // ctx.shadowBlur = 50;
                    ctx.fillStyle = text.color;
                    ctx.fillText(text.string, this.x, this.y);
                } else {
                    ctx.strokeStyle = text.color;
                    ctx.lineWidth = 2;
                    ctx.strokeText(text.string, this.x, this.y);
                }
    
            }
    
            this.move = function() {
    
    
                this.radians += this.easeSpeed
                this.easeVal = (Math.cos(this.radians)) + 1  // Plus one to avoid negative values
    
    
                let textWidth = ctx.measureText(text.string).width;
                let speed = text.dx
    
                if(ease) {
    
                    speed = easingFunctions.easeInOutCubic(this.easeVal * 1.5) + 3;
    
                } else {
                    speed = text.dx;
                }
    
    
                // Move text
                this.x -= speed;
    
    
                // Add to the end of count if the text disappears from canvas
                if(this.x <= 0 - textWidth) {
                    this.x = textWidth * (text.countX - 1);
                }
    
            }
    
    
            this.update = function(dir) {
                this.draw();
                this.move();
            }
    
        }
    
    
        // Create empty object array
        let wordsArray = [];
    
        // Output initial text to generate width
        ctx.fillText(text.string, 0, 0);
        let textWidth = ctx.measureText(text.string).width;
    
        // Values for loop count
        text.countX = Math.ceil(canvas.width / textWidth) + 1;
        text.countY = Math.ceil(canvas.height / 220) + 1;
        let countMax = text.countX * text.countY;
    
    
        // Default coords
        let posX = -50
        let posY = -75
    
    
        // Loop through to create text objects
        for (var i = 1; i < countMax; i++) {
    
            posY = posY + 250;
    
            if(i % text.countY === 0) {
    
                posX = posX + textWidth;
                posY = -75
            }
    
            let fill = false;
            if(i % 4 === 0) {
                fill = true
            }
    
            // Adjust speed/easing
            let ease = false
            if(i % 2 === 0 ){
                ease = true
            }
    
            // Add new word instance to array
            wordsArray.push(new DrawText(posX, posY, fill, ease))
    
        }
    
        //console.log(wordsArray)
    
    
    
        // Animation loop
        function animate(event) {
    
            if(event)
    
            // Clear canvas of previous frame before outputting next
            ctx.clearRect(0,0,canvas.width, canvas.height);
    
            // Loop through text instances instances to output/update
            for (var i = 0; i < wordsArray.length; i++) {
                wordsArray[i].update();
            }
    
    
            // Begin frame loop
            requestAnimationFrame(animate);
        }
    
    
        function init() {
            animate();
            // Add additional functions or scripts here for startup
        }
    
    
        // Init
        init();
    }

    render() {

        return(
            <section className="section-text">
                <div className="section-text__wrapper">
                    <canvas ref="text"></canvas>
                </div>
            </section>
        )
    }
    
}

export default Text
