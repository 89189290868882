import React from 'react'
import {Helmet} from 'react-helmet'
import SEO from './seo.js'

import Wotd from '../assets/images/wotd.inline.svg'

class Header extends React.Component {


    render() {

        return (
            <>  
                <SEO />
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Yeah Right | Award-Winning Freelance Web Development</title>
                    <link rel="canonical" href="https://yeah-right.co" />
                </Helmet>
                <header>
                    <a href="https://www.cssdesignawards.com/sites/yeah-right/36637/" className="wotd" target="_blank">  
                        <Wotd />
                    </a>
                </header>
            </>
        )
    }

}


export default Header
