import React from 'react'
import {titleIntro} from '../helpers/utils'

// Components
import Mascot from '../assets/images/mascot-large.inline.svg'

class Hero extends React.Component {

    constructor() {
        super();
        this.state = {
            mascotHover: null
        }
        this.toggleHover = this.toggleHover.bind(this)
    }

    toggleHover() {
        if(this.state.mascotHover == null) {
            this.setState({mascotHover: true});
        } else {
            this.setState({mascotHover: !this.state.mascotHover})
        }
       
    }


    render() {

        // Split title text into lines
        let title = titleIntro("yeah right.")
        let mascotClass = ''

        if(this.state.mascotHover == null) {
            mascotClass = ''
        } else if(this.state.mascotHover == true){
            mascotClass = 'is-inview hover--active'
        } else {
            mascotClass = 'is-inview '
        }

        return (
            <section className="section-hero">
                <div className="container container--fluid min-h-screen mx-auto">
                    <div className="section__left sm:w-2/3 lg:w-3/4">
                        <div className="section__title" data-scroll data-scroll-offset="33%">
                            {title}
                        </div>
                        <div className="section__content" data-scroll data-scroll-offset="33%">
                            <span className="section__subheading font-serif">yeah, right... (exp.)</span>
                            <p><strong>Pronunciation: (disbelief) [jɛə̯˥˨ ɹaɪt˥˩]</strong></p>
                            <ol>
                                <li>A colloquial term often used in a satirical context to express disbelief in an implausible or unreliable concept or methodology.</li>
                            </ol>
                        </div>
                    </div>
                    <div className="section__right sm:w-1/3 lg:w-1/4 hidden md:inline">
                        <div className={`section__mascot-wrap ${mascotClass}`} data-scroll data-scroll-speed="-5" data-scroll-offset="20%" onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover}>
                            <Mascot />
                        </div>
                    </div>
                </div>
            </section>
        )
    }

}


export default Hero
