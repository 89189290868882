import React, { useEffect, createRef }from 'react'
import lottie from 'lottie-web'
import animation from '../helpers/yeahright.json'

// Components
import Intro from '../components/intro'


function Loader()  {

        let animContainer = createRef();

        useEffect(() => {
            const anim = lottie.loadAnimation({
              container: animContainer.current,
              renderer: "svg",
              loop: false,
              autoplay: false,
              animationData: animation
            });

            setTimeout(function(){
                anim.play() 
            }, 4000);

        }, []);


        return (
            <>
            <Intro />
            <section className="section-loader">
                <div className="container min-h-screen mx-auto flex items-center">
                    <div className="section-loader__content inline-block mx-auto pb-20">
                        <div className="section-loader__anim" ref={animContainer}></div>
                        <div className="loader-next text-center">
                            <span className="button button--arrow-down">This Way</span>
                        </div>
                    </div>
                </div>
            </section>
            </>
        )

}

export default Loader;  

