import React from 'react'


const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

class Contact extends React.Component {
    
        constructor() {
          super();
          this.state = {
              name: "",
              email: "",
              message: "",
              successMsg: false,
              errorMsg: false
            }
        }

    
        handleSubmit = e => {

            // Stop default submissino process
            e.preventDefault();

            // Perform AJAX submission
            fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({ "form-name": "contact", ...this.state })
            })
            .then(() => {
                this.setState({successMsg: true, errorMsg: false})
                let current = this
                setTimeout(function(){
                    current.setState({successMsg: false})
                }, 4000)
            })
            .catch(error => {
                this.setState({errorMsg: true, successMsg: false})
                let current = this
                setTimeout(function(){
                    current.setState({errorMsg: false})
                }, 4000)
            })
    
        };
    

        handleChange = e => this.setState({ [e.target.name]: e.target.value });

    render() {

        const {name, email, message} = this.state
        let success = this.state.successMsg ? 'submit--success' : ''
        let error = this.state.errorMsg ? 'submit--error' : ''

        return (
            <section className="section-contact">
                <div className="container container--fluid min-h-screen mx-auto">
                    <div className="sm:flex">
                        <div className="section__left sm:w-1/5 lg:w-1/4">
                            <span className="inline-block" data-scroll data-scroll-speed="2">
                                <h1>Say Hello</h1>
                            </span>
                        </div>
                        <div className="section__right sm:w-4/5 lg:w-3/4">
                            <div className="section__content" data-scroll data-scroll-offset="90%" data-scroll-speed="-7.75">
                                <span className="section__subheading font-serif">spam me</span>
                                <p>Complete the form below or, if you’re a purist, <a href="mailto:hello@yeahright.co">email me</a></p>
                                <div className={`section-contact__form  ${success} ${error}`}>
                                    <form autcomplete="off" onSubmit={this.handleSubmit} name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-stop">
                                        <input type="hidden" name="form-name" value="contact" />
                                        <p className="hidden"><label>Don’t fill this out if you're human: <input name="bot-stop" /></label></p>
                                        <div className="sm:w-1/2 block sm:inline-block">
                                            <input type="text" name="name" placeholder="Name" value={name} onChange={this.handleChange}/>
                                        </div>
                                        <div className="sm:w-1/2 block sm:inline-block">
                                            <input type="text" name="email" placeholder="Email" value={email} onChange={this.handleChange} />
                                        </div>
                                        <div>
                                            <textarea name="message" rows="7" placeholder="Message" value={message} onChange={this.handleChange} ></textarea>
                                        </div>
                                        <div className="text-right">
                                            <button type="submit" className="button--arrow-right"><span>Let's do this!</span></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

}


export default Contact
