import React from 'react'


class Cursor extends React.Component {

    componentDidMount() {

        //-------------------------------
        // INIT CANVAS CONTEXT
        //-------------------------------

        // Create canvas context
        const canvas = this.refs.cursor;

        if(canvas && window.innerWidth > 992) {
            const ctx = canvas.getContext('2d');

            ctx.canvas.width = window.innerWidth;
            ctx.canvas.height = window.innerHeight;

            // Size canvas to window dimensions
            ctx.canvas.style.width = window.innerWidth;
            ctx.canvas.style.height = window.innerHeight;

            window.addEventListener('resize', function() {
                ctx.canvas.width = window.innerWidth;
                ctx.canvas.height = window.innerHeight;
            });

            //-------------------------------
            // CONFIG
            //-------------------------------

            // Defaults
            let mouseX = canvas.width / 2
            let mouseY = canvas.height * 0.75
            let xPos = 0
            let yPos = 0


            // Cursor object
            const cursor = {
                x : mouseX, // Horiz center
                y : mouseY, // Vert center
                radius : 2.5,
                colour : "black"
            }


            const cursorTail = {
                x : mouseX, // Horiz center
                y : mouseY, // Vert center
                radius : 20,
                colour : "transparent",
                strokeWidth: 10,
                strokeColour:"#888"
            }



            //-------------------------------
            // DRAW FUNCTIONS
            //-------------------------------

            // Function to draw circles
            function drawArc(x, y, r, colour, strokeWidth, strokeColour){
                ctx.beginPath();
                ctx.arc(x, y, r, 0, Math.PI * 2);
                if(strokeWidth) {
                    ctx.linewidth = strokeWidth
                }
                if(strokeColour) {
                    ctx.strokeStyle = strokeColour
                }
                if(strokeWidth || strokeColour) {
                    ctx.stroke()
                }
                if(colour) {
                    ctx.fillStyle = colour;
                }
                ctx.fill();
            }

            function setMousePosition(e) {
                mouseX = e.clientX;
                mouseY = e.clientY;
            }

            window.addEventListener('mousemove', setMousePosition);




            //-------------------------------
            // RUN ANIMATIONS (AND LOOP)
            //-------------------------------


            // Outputs all the elements to the canvas element
            function animate() {

                let dX = mouseX - xPos;
                let dY = mouseY - yPos;

                xPos += (dX / 10);
                yPos += (dY / 10);

                cursor.x = mouseX
                cursor.y = mouseY

                cursorTail.x = xPos
                cursorTail.y = yPos


                // Render Canvas BG
                ctx.clearRect(0,0,canvas.width, canvas.height)

                // Render cursor elements
                drawArc(cursor.x, cursor.y, cursor.radius, cursor.colour,);
                drawArc(cursorTail.x, cursorTail.y, cursorTail.radius, cursorTail.colour, cursorTail.strokeWidth, cursorTail.strokeColour);

                // Start loop
                requestAnimationFrame(animate);
            }



            // Init animation
            animate();
        }

    }


    render() {
        return (
            <>
                <canvas id="cursor" className="content-fixed" ref="cursor"></canvas>
            </>
        )
    }

}


export default Cursor
