import React from 'react'


class Footer extends React.Component {


    render() {
       return (
        <footer className="section-social" data-scroll data-scroll-offset="1%">
            <div className="container container--fluid">
                <div className="section__content flex items-center justify-between" data-scroll>
                    <div className="text-center lg:text-left lg:w-1/2">
                        <h2>@yeahrightmcr</h2>
                    </div>
                    <div className="lg:w-1/2 text-right">
                        <ul className="m-0 p-3 text-center lg:text-right">
                            <li>
                                <a href="https://instagram.com/yeahrightmcr" rel="noopener" target="_blank">
                                    <span>Ig.</span>
                                </a>
                            </li>
                            <li>
                                <a href="https://linkedin.com/company/yeahrightmcr" rel="noopener" target="_blank">
                                    <span>In.</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
        )
    }

}


export default Footer
