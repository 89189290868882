import React from 'react'

// Components
import Cursor from '../components/cursor'
import Header from '../components/header'
import Footer from '../components/footer'

import LocomotiveScroll from 'locomotive-scroll';

// Stylesheet
import '../assets/scss/main.scss'

class Layout extends React.Component {

    componentDidMount = () => {

        document.body.classList.add('loader--text');

        // Remove loader class after 3s
        setTimeout(() => {
            document.body.classList.add('loader--hide');
        }, 3000);

        // Init mascot animation after 4s
        setTimeout(() => {
            document.body.classList.add('anim--init');
        }, 4000);

        let current = this;
            
        const scroll = new LocomotiveScroll({
            el: document.querySelector("#wrapper"),
            smooth: true,
            inertia: 0.85
        });

    }

    render() {

        const { children } = this.props

        return (
            <>
                <Cursor />
                <Header />
                <div  id="wrapper">
                    <main>
                        {children}
                    </main>
                    <Footer />
                </div>
            </>
        )
    }
}

export default Layout
