import React from 'react'
import {titleIntro} from '../helpers/utils'


class About extends React.Component {

    componentDidMount() {

        //-------------------------------
        // INIT CANVAS CONTEXT
        //-------------------------------

        // Create canvas context
        const canvas = this.refs.particles;
        const ctx = canvas.getContext('2d');

        // Size canvas to window dimensions
        if(window.innerWidth < 1024) {
            ctx.canvas.width = window.innerWidth;
        } else {
            ctx.canvas.width = window.innerWidth / 2
        }
        ctx.canvas.height = window.innerHeight;

        window.addEventListener('resize', function() {
            if(window.innerWidth < 1024) {
                ctx.canvas.width = window.innerWidth;
            } else {
                ctx.canvas.width = window.innerWidth / 2
            }
            ctx.canvas.height = window.innerHeight;
        });

        //-------------------------------
        // CONFIG
        //-------------------------------

        // Defaults
        const particle = {
            r: Math.floor(Math.random() * (50 - 20 + 1)) + 20,
            count: 12,
            speed: 2.5,
            ox: 0.01
        }

        let particles = [];
        for(let i = 0; i < particle.count; i++) {

            let x = Math.random() * canvas.width
            let y = Math.random() * canvas.height
            let r = Math.floor(Math.random() * (40 - 1 + 1)) + 40

            // Stop the particles being spawned past canvas boundaries
            if(x < r) {
                x = r
            } else if(x > ctx.canvas.width - r) {
                x = ctx.canvas.width - r;
            }

            if(y < r) {
                y = r
            } else if (y > ctx.canvas.height - r) {
                y = ctx.canvas.height - r
            } 

            particles.push(new ParticleObj(x, y, r))
        }



        //-------------------------------
        // DRAW FUNCTIONS
        //-------------------------------

        // Object to create particles
        function ParticleObj(x,y,r) {
            this.x = x
            this.y = y
            this.r = r
            this.alpha = Math.random() * 0.25
            this.colour = `rgba(165,165,174,${this.alpha})`
            this.dx = (Math.random() - 0.5) * particle.speed
            this.dy = (Math.random() - 0.5) * particle.speed

            this.draw = function() {
                drawArc(this.x, this.y, this.r, this.colour)
            }

            this.update = function() {

                // Update current position by velocity
                this.x += this.dx
                this.y += this.dy

                this.alpha += particle.ox

                // Bounce particle off left and right
                if(this.x + this.r + this.dx > canvas.width || this.x - this.r + this.dx < 0) {
                    this.dx = -this.dx
                }

                // Bounce particle off top and bottom
                if(this.y + this.r + this.dy > canvas.height || this.y - this.r + this.dy < 0) {
                    this.dy = -this.dy
                }

                this.draw();
            }

        }

        // Function to draw circles
        function drawArc(x, y, r, colour, strokeWidth, strokeColour){
            ctx.beginPath();
            ctx.arc(x, y, r, 0, Math.PI * 2);
            if(strokeWidth) {
                ctx.linewidth = strokeWidth
            }
            if(strokeColour) {
                ctx.strokeStyle = strokeColour
            }
            if(strokeWidth || strokeColour) {
                ctx.stroke()
            }
            if(colour) {
                ctx.fillStyle = colour;
            }
            ctx.fill();
        }




        //-------------------------------
        // RUN ANIMATIONS (AND LOOP)
        //-------------------------------


         // Outputs all the elements to the canvas element
        function animate() {


            // Render Canvas BG
            ctx.clearRect(0,0,canvas.width, canvas.height)

            // Render floating particles
            particles.forEach((p) => {
                p.update();
            })

            // Start loop
            requestAnimationFrame(animate);
        }



        // Init animation
        animate();

    }


    render() {

        // Split title text into lines
        let title = titleIntro("About")

        return (
            <section className="section-about section--right flex">
                <span data-scroll data-scroll-offset="50%">
                    <canvas id="particles" ref="particles"></canvas>
                </span>
                <div className="container container--fluid min-h-screen mx-auto">
                    <div className="section__left sm:w-2/3 lg:w-3/5">
                        <div className="section__title" data-scroll data-scroll-offset="33%">
                            {title}
                        </div>
                        <div className="section__content" data-scroll data-scroll-offset="33%">
                            <span className="section__subheading font-serif">who am I?</span>
                            <p><strong>Hi, I’m Tom, a freelance full-stack developer based in Manchester, UK. I work with individuals and agencies to craft elegant web interfaces.</strong></p>
                            <p>Previous clients include Sony Music Entertainment, Dulux, Swatch & NHS Teaching Hospitals during which time I have been lucky enough to receive recognition from a number of award bodies.</p>
                        </div>
                    </div>
                    <div className="section__right sm:w-1/3 lg:w-2/5">
                    </div>
                </div>
            </section>
        )
    }

}


export default About
