import React from 'react'

export const titleIntro = (string) => {

    let term = [];

    // Split by lines
    term = string.split(' ');


    const html = term.map((word, index) => {

        // Split by letter
        let letters = word.split('');

        let lettersHtml = letters.map((letter, index) => {
        return <span className="intro-title__letter" key={index}>{letter}</span>
        })

        return (
            <span className="intro-title__word" key={index} data-line={index + 1}>
                <div className="intro-title__word-wrap">
                {lettersHtml}
                </div>
            </span>
        )

    })

    return (
        <h1 className="intro-title">
            {html}
        </h1>
    )

}




export const stringSpanWrap = (string) => {

    // Split by lines
    let letters = string.split('');


    const html = letters.map((letter, index) => {

        if(letter == ' ') {
            return <span key={index} className="space">{letter}</span>
        } else {
            return <span key={index}>{letter}</span>
        }



    })

    return <>{html}</>

}



export const easingFunctions = {
    linear: function (t) {
        return t
    },
    easeInQuad: function (t) {
        return t * t
    },
    easeOutQuad: function (t) {
        return t * (2 - t)
    },
    easeInOutQuad: function (t) {
        return t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t
    },
    easeInCubic: function (t) {
        return t * t * t
    },
    easeOutCubic: function (t) {
        return (--t) * t * t + 1
    },
    easeInOutCubic: function (t) {
        return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
    },
    easeInQuart: function (t) {
        return t * t * t * t
    },
    easeOutQuart: function (t) {
        return 1 - (--t) * t * t * t
    },
    easeInOutQuart: function (t) {
        return t < .5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t
    },
    easeInQuint: function (t) {
        return t * t * t * t * t
    },
    easeOutQuint: function (t) {
        return 1 + (--t) * t * t * t * t
    },
    easeInOutQuint: function (t) {
        return t < .5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t
    }
}