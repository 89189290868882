import React from 'react'
import {stringSpanWrap} from '../helpers/utils'


class Loader extends React.Component {


    render() {

        let title = stringSpanWrap('For best results use chrome', false);

        return (
            <>
            <section className="section-intro flex items-center">
                <div className="section-intro__text">
                    {title}
                </div>
            </section>
            </>
        )
    }

}


export default Loader
